import { BlogPost } from 'cms-types'
import { BlogPostCardType } from 'ui'

import { getCollectionItem } from '@/cms'
import { resolveRelation } from '@/cms/helpers'

export async function mapCMSBlogToBlogPostCard(
  blogPost: BlogPost | string,
  linkTitle: string,
  locale: string,
): Promise<BlogPostCardType> {
  if (typeof blogPost === 'string') {
    return getCollectionItem(blogPost, 'blogPosts', { searchParams: { locale } }).then(
      ({ preview = {}, title = '', id = '', slug = '' }) => {
        const { subtitle = '', description = '', image = '' } = preview

        if (typeof image === 'string') {
          return getCollectionItem(image, 'images', { searchParams: { locale } }).then(
            fetchedPreviewImage => ({
              id,
              slug,
              title,
              preview: {
                subtitle,
                linkTitle,
                description,
                image: resolveRelation(fetchedPreviewImage),
              },
            }),
          )
        }

        return {
          id,
          slug,
          title,
          preview: {
            subtitle,
            linkTitle,
            description,
            image: resolveRelation(image),
          },
        }
      },
    )
  }

  const { subtitle, description, image } = blogPost.preview

  return {
    id: blogPost.id,
    slug: blogPost.slug,
    title: blogPost.title,
    preview: {
      subtitle,
      linkTitle,
      description,
      image: resolveRelation(image),
    },
  }
}
