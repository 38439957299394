import { HeroIngredientsBlock } from 'cms-types'
import { LocalizedComponentProps } from 'ui'
import { MappedIngredient } from 'ui/modules/HeroIngredients'

import { richTextToJSX } from '@/helpers/richTextParser'

import { getCollectionItem } from '../../'
import { pageToUrl, resolveRelation } from '../../helpers'
import { HeroIngredientsClient } from './HeroIngredientsClient'

export default async function HeroIngredients({
  ingredients,
  title,
  locale,
}: LocalizedComponentProps<HeroIngredientsBlock>) {
  const mappedIngredients = await Promise.all(
    (ingredients ?? []).map(async ({ ingredient, image, overrideImage }) => {
      const fetchedIngredient =
        typeof ingredient === 'string'
          ? await getCollectionItem(ingredient, 'ingredients', { searchParams: { locale } })
          : ingredient

      if (!fetchedIngredient) {
        return null
      }

      const { previewImage, description, page } = fetchedIngredient

      const currentImage = overrideImage && image ? image : previewImage

      return {
        ...fetchedIngredient,
        previewImage: resolveRelation(currentImage),
        description: richTextToJSX(description, {
          textOnly: true,
        }),
        page: pageToUrl(page),
      }
    }),
  )

  return (
    <HeroIngredientsClient
      ingredients={mappedIngredients.filter(data => !!data) as MappedIngredient[]}
      title={title}
    />
  )
}
