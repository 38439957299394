import { AboutCardBlock } from 'cms-types'
import { AboutCard as UIAboutCard, LoadableComponentProps } from 'ui'

import { pageToUrl, resolveRelation } from '../helpers'

export default function AboutCard({ loading, data }: LoadableComponentProps<AboutCardBlock>) {
  return loading ? (
    <UIAboutCard loading />
  ) : (
    <UIAboutCard
      data={{
        title: data.title,
        description: data.enableDescription ? data.description : undefined,
        media: resolveRelation(data.image || data.video),
        buttonText: data.buttonText,
        url: pageToUrl(data.page),
        invertedDesignColors: data.invertedDesignColors,
      }}
    />
  )
}

AboutCard.loadable = true
