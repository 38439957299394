import { TestimonialsBlock } from 'cms-types'
import { LoadableComponentProps, Testimonials as UITestimonials } from 'ui'

import { resolveRelation } from '../helpers'

export default function Testimonials({ data, loading }: LoadableComponentProps<TestimonialsBlock>) {
  return loading ? (
    <UITestimonials loading />
  ) : (
    <UITestimonials
      data={{
        ...data,
        testimonials:
          data.testimonials?.map(({ image, ...restProps }) => ({
            ...restProps,
            image: resolveRelation(image),
          })) ?? [],
      }}
    />
  )
}

Testimonials.loadable = true
