import { RichTextBlock } from 'cms-types'

import { richTextToJSX } from '@/helpers/richTextParser'

export default function RichText({ content }: RichTextBlock) {
  return (
    <div className='mx-auto my-9 box-content max-w-[940px] px-5 xl:px-10'>
      {richTextToJSX(content)}
    </div>
  )
}
