import { ProductHintCardBlock } from 'cms-types'

import { getCollectionItem } from '@/cms'
import { pageToUrl, resolveRelation } from '@/cms/helpers'

import { ProductHintCardProps } from './../cms/blocks/ProductHintCard'

export const mapProductHintCard = async (props: ProductHintCardProps, locale: string) => {
  const { useAnExistingTemplate, template, ...restProps } = props

  let card: Omit<ProductHintCardBlock, 'template'>

  if (useAnExistingTemplate && template) {
    card = (
      typeof template === 'string'
        ? await getCollectionItem(template, 'productHintCardTemplates', {
            searchParams: { locale },
          })
        : await getCollectionItem(template.id, 'productHintCardTemplates', {
            searchParams: { locale },
          })
    ).card[0]
  } else {
    card = {
      ...restProps,
    }
  }

  const { pageLink, image, title, description, buttonText, isFullWidth } = card

  const mappedUrl = pageLink?.customLink ? pageLink?.link : pageToUrl(pageLink?.page)

  const pageUrl =
    !pageLink?.customLink &&
    pageLink?.page?.relationTo === 'productPages' &&
    pageLink?.productVariant
      ? `${mappedUrl}?variant=${pageLink?.productVariant}`
      : mappedUrl

  return {
    image: resolveRelation(image),
    title,
    description,
    buttonText,
    url: pageUrl,
    openInNewTab: pageLink?.customLink && pageLink?.openInNewTab,
    isFullWidth,
  }
}
