import { PhilosophieValuesBlock } from 'cms-types'
import { LoadableComponentProps, PhilosophieValues as PhilosophieValuesUI } from 'ui'

import { richTextToJSX } from '@/helpers/richTextParser'

import { resolveRelation } from '../helpers'

export default function PhilosophieValues({
  data,
  loading,
}: LoadableComponentProps<PhilosophieValuesBlock>) {
  return loading ? (
    <PhilosophieValuesUI loading />
  ) : (
    <PhilosophieValuesUI
      data={{
        ...data,
        description: richTextToJSX(data.description, { textOnly: true }),
        productFeatures:
          data.productFeatures?.map(({ id, text, image }) => ({
            id,
            text,
            image: resolveRelation(image),
          })) ?? [],
      }}
    />
  )
}

PhilosophieValues.loadable = true
