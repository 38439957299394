import { EngagementBlock } from 'cms-types'
import { Engagement as EngagementUI, LoadableComponentProps } from 'ui'

import { richTextToJSX } from '@/helpers/richTextParser'

import { resolveRelation } from '../helpers'

export default function Engagement({ data, loading }: LoadableComponentProps<EngagementBlock>) {
  const projectsContent = data?.projects?.map(({ image, backgroundColor, mixBlendMode }) => ({
    image: resolveRelation(image),
    backgroundColor,
    mixBlendMode: mixBlendMode,
  }))

  return loading ? (
    <EngagementUI loading />
  ) : (
    <EngagementUI
      data={{
        ...data,
        description: richTextToJSX(data.description, { textOnly: true }),
        video: resolveRelation(data.video),
        projects: projectsContent,
      }}
    />
  )
}

Engagement.loadable = true
