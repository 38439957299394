import { BlogTipCardBlock } from 'cms-types'
import React from 'react'
import { BlogTipCard as BlogTipCardUI, type LoadableComponentProps } from 'ui'

import { richTextToJSX } from '@/helpers/richTextParser'

function BlogTipCard({ data, loading }: LoadableComponentProps<BlogTipCardBlock>) {
  if (loading) return <BlogTipCardUI loading />
  return <BlogTipCardUI data={{ text: richTextToJSX(data.text) || [] }} />
}

export default BlogTipCard
BlogTipCard.loadable = true
