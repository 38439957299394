import { Config } from 'cms-types'

import AboutCard from './AboutCard'
import Accordion from './Accordion'
import BlogTeaser from './BlogTeaser'
import BlogTipCard from './BlogTipCard'
import Carousel from './Carousel'
import CommunityTeaser from './CommunityTeaser'
import ContentGrid from './ContentGrid'
import DynamicCode from './DynamicCode'
import Engagement from './Engagement'
import FeatureBlogPosts from './FeatureBlogPosts'
import FeatureProducts from './FeatureProducts/FeatureProducts'
import HelpCard from './HelpCard'
import HeroFeatures from './HeroFeatures'
import HeroInfoBlock from './HeroInfoBlock'
import HeroIngredients from './HeroIngredients'
import Image from './Image'
import ImageSplitView from './ImageSplitView'
import ImageText from './ImageText'
import InfoCardList from './InfoCardList'
import PageHeader from './PageHeader'
import PageTitle from './PageTitle'
import PhilosophieValues from './PhilosophieValues'
import ProductHintCard from './ProductHintCard'
import ProductReviews from './ProductReviews'
import RichText from './RichText'
import SetGuide from './SetGuide'
import SetTeaser from './SetTeaser'
import ShopTeaser from './ShopTeaser'
import SuggestionCard from './SuggestionCard'
import TableBlock from './TableBlock'
import TeaserSlider from './TeaserSlider'
import Testimonials from './Testimonials'
import UniqueSellingPoints from './UniqueSellingPoints'

export type AnyBlock = NonNullable<Config['globals']['allBlocks']['blocks']>[number]

export const blocks: Record<
  AnyBlock['blockType'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...args: any[]) => JSX.Element | null | Promise<JSX.Element | null>
> = {
  PageTitle,
  PageHeader,
  Image,
  SetTeaser,
  SuggestionCard,
  TeaserSlider,
  AboutCard,
  UniqueSellingPoints,
  HelpCard,
  Testimonials,
  ShopTeaser,
  CommunityTeaser,
  HeroFeatures,
  FeatureProducts,
  BlogTeaser,
  Accordion,
  RichText,
  FeatureBlogPosts,
  HeroInfoBlock,
  ImageSplitView,
  ProductHintCard,
  BlogTipCard,
  ProductReviews,
  ImageText,
  PhilosophieValues,
  InfoCardList,
  Carousel,
  TableBlock,
  HeroIngredients,
  SetGuide,
  ContentGrid,
  Engagement,
  DynamicCode,
}
