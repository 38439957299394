import { HelpCardBlock } from 'cms-types'
import { HelpCard as UIHelpCard, LoadableComponentProps } from 'ui'

import { resolveRelation } from '../helpers'

export default function HelpCard({ data, loading }: LoadableComponentProps<HelpCardBlock>) {
  return loading ? (
    <UIHelpCard loading />
  ) : (
    <UIHelpCard
      data={{
        ...data,
        image: resolveRelation(data.image),
      }}
    />
  )
}

HelpCard.loadable = true
