import { ImageSplitViewBlock } from 'cms-types'
import React from 'react'
import { ImageSplitView as ImageSplitViewUI } from 'ui'

import { resolveRelation } from '@/cms/helpers'

export default function ImageSplitView({ content }: ImageSplitViewBlock) {
  const mappedContent =
    content?.map(({ caption, image }) => {
      return {
        image: resolveRelation(image),
        caption,
      }
    }) || []

  return <ImageSplitViewUI content={mappedContent} />
}
