import { Image, Product, ProductCardBlock, ProductPage } from 'cms-types'
import { Product as ShopifyProduct } from 'shopify-types'
import { getShopifyGID } from 'shopify-types/helpers'
import { ProductForProductCard, ProductInfo } from 'ui'
import { SetCreatorProduct } from 'ui/modules/SetCreator'

import { getCollectionItem } from '@/cms'
import { CMS_URL } from '@/cms/getCollection'
import { pageToUrl } from '@/cms/helpers'
import { shopify } from '@/shopify'
import { useGlobalTypography } from '@/store/globalTypographyStore'

import { logger } from './logger'
import { ProductVariantWithProductId } from './mapProductVariants'
import { getTextFromRichText } from './richTextParser'

type ProductWithData = Omit<ProductCardBlock, 'productVariant'> & {
  productVariant: NonNullable<Product['variants']>[number]
  product: Product
}

export const getProductCardsForCMSProductCardBlocks = async (
  products: ProductCardBlock[],
  locale: string,
): Promise<ProductForProductCard[]> => {
  const productsWithData = await Promise.all(
    products.map(async ({ productVariant, page, ...restData }) => {
      const productPage = page?.value as ProductPage
      const product = productPage?.product as Product

      if (!product) {
        return null
      }

      const variant = product.variants?.find(({ id, shopifyId }) =>
        [id, shopifyId].includes(productVariant),
      )

      return {
        page,
        product,
        productVariant: variant,
        ...restData,
      }
    }),
  )

  const filteredProductsWithData = productsWithData.filter(Boolean) as ProductWithData[]

  const productIds = Array.from(
    new Set(filteredProductsWithData.map(({ product }) => product.shopifyId)),
  ).map(id => getShopifyGID(id, 'Product'))

  try {
    const shopifyProducts = await shopify({
      language: locale.toUpperCase(),
    }).product.fetchMultiple(productIds)

    const shopifyProductsMap = new Map(shopifyProducts.map(product => [product.id, product]))

    const mappedProducts = await Promise.all(
      mapCMSProductCardBlocksToProductCards(filteredProductsWithData, shopifyProductsMap, locale),
    )

    return mappedProducts
      .filter(Boolean)
      .filter(product => product?.shown) as ProductForProductCard[]
  } catch (e) {
    logger.error('Error fetching shopify products', e)
    throw e
  }
}

export const mapCMSProductVariantsToProductCards = async (
  productVariants: ProductVariantWithProductId[] = [],
  locale: string,
) => {
  const productBlocks: ProductCardBlock[] = await Promise.all(
    productVariants.map(async productVariant => {
      const { productId } = productVariant

      const productPagesResponse = await fetch(
        `${CMS_URL}/api/productPages?where[product][in]=${productId}`,
        {
          next: { revalidate: 0 },
        },
      )
        .then(res => res.json() as Promise<{ docs: ProductPage[] }>)
        .then(res => res.docs)

      return {
        blockType: 'ProductCard',
        productVariant: productVariant.id as string,
        page: {
          value: productPagesResponse[0],
          relationTo: 'productPages',
        },
      }
    }),
  )

  return getProductCardsForCMSProductCardBlocks(productBlocks, locale)
}

export const mapCMSProductVariantsToSetCreatorProducts = async (
  products: Array<Product & { selectedVariantId: string }>,
  locale: string,
): Promise<{ currency: string; products: SetCreatorProduct[] }> => {
  let currency = ''

  // Fetch the variants for each product
  const fetchedVariants = await Promise.all(
    products?.map(async product => {
      const {
        shopifyId: productId,
        selectedVariantId,
        variants: productVariants,
        ...rest
      } = product

      const { variants, title } = await shopify({
        language: locale.toUpperCase(),
      }).product.fetch(getShopifyGID(productId, 'Product'))

      const currentShopifyVariant = selectedVariantId
        ? variants.find(variant => variant.id.includes(selectedVariantId))
        : null

      if (currentShopifyVariant == null) {
        return null
      }

      const { price, id, compareAtPrice } = currentShopifyVariant

      if (currency.length === 0) {
        currency = price.currencyCode
      }

      const variantNameOption = currentShopifyVariant.selectedOptions.find(
        ({ name }) => name === 'Name',
      )

      const selectedVariant: NonNullable<SetCreatorProduct['variants']>[0] | undefined =
        productVariants?.find(({ shopifyId }) => {
          return shopifyId === selectedVariantId
        })

      const variantImage = selectedVariant?.productInfo?.images[0].image
      let fetchedImage: Image | null = null
      if (typeof variantImage === 'string') {
        fetchedImage = await getCollectionItem(variantImage, 'images')
      }

      const overviewDescription = getTextFromRichText(
        selectedVariant?.productInfo?.overviewDescription,
      )

      return {
        shopifyId: productId,
        description:
          overviewDescription !== ''
            ? overviewDescription
            : getTextFromRichText(selectedVariant?.productInfo?.description),
        productId,
        variants: selectedVariant ? [selectedVariant] : [],
        ...rest,
        image: fetchedImage || variantImage,
        variantId: id,
        title: variantNameOption ? `${title} ${variantNameOption.value}` : title,
        price: price.amount.toString(),
        currency: price.currencyCode,
        compareAtPrice:
          compareAtPrice && compareAtPrice.amount > price.amount
            ? { ...compareAtPrice }
            : undefined,
        imageAlt: title,
        pageUrl: `/products/${productId}`,
        isAvailable: currentShopifyVariant.availableForSale,
        outOfStockTag: useGlobalTypography.getState().productPage.outOfStockTag,
      }
    }) ?? [],
  )

  const filteredVariant = fetchedVariants.filter(Boolean) as unknown as SetCreatorProduct[]

  return {
    currency,
    products: filteredVariant,
  }
}

const mapCMSProductCardBlocksToProductCards = (
  filteredProductsWithData: ProductWithData[],
  shopifyProductsMap: Map<string, ShopifyProduct>,
  locale: string,
) => {
  return filteredProductsWithData.map(async ({ productVariant, page, product, tag }) => {
    const productId = product.shopifyId

    const shopifyProduct = shopifyProductsMap.get(`gid://shopify/Product/${productId}`)

    if (shopifyProduct == null) return null

    const { title, variants } = shopifyProduct

    const variant = variants.find(({ id }) => id.includes(productVariant?.shopifyId as string))

    if (variant == null) return null

    const [firstImage, hoverImage] = await Promise.all(
      (productVariant?.productInfo?.images as NonNullable<ProductInfo['images']>).map(
        async ({ image }) => {
          if (typeof image === 'string') {
            return await getCollectionItem(image, 'images', {
              searchParams: { locale },
            })
          }

          return image
        },
      ),
    )

    const volume = variant.selectedOptions.find(({ name }) => name === 'Size')
    const variantNameOption = variant.selectedOptions.find(({ name }) => name === 'Name')

    const productDetails = page.value as ProductPage

    const [firstPDPImage] =
      productDetails.productIntro?.variantImages?.find(
        ({ variant }) => variant === productVariant?.id,
      )?.images ?? []

    const productTag = tag?.isPriority ? tag : firstPDPImage?.tag ?? tag
    const overviewDescription = getTextFromRichText(
      productVariant?.productInfo?.overviewDescription,
    )
    return {
      title: variantNameOption ? `${title} ${variantNameOption.value}` : title,
      description:
        overviewDescription !== ''
          ? overviewDescription
          : getTextFromRichText(productVariant?.productInfo?.description),
      productId,
      variantId: variant.id,
      image: firstImage as Image,
      hoverImage,
      imageAlt: title,
      isAvailable: 'availableForSale' in variant ? variant.availableForSale : false,
      outOfStockTag: useGlobalTypography.getState().productPage.outOfStockTag,
      volume: volume?.value,
      price: variant.price.amount.toString(),
      currency: variant.price.currencyCode,
      compareAtPrice:
        variant.compareAtPrice && variant.compareAtPrice.amount > variant.price.amount
          ? variant.compareAtPrice
          : undefined,
      pageUrl: pageToUrl(page) ?? '',
      tag: productTag,
      shown: !variant.hidden,
    }
  })
}
