import { HeroInfoBlockBlock } from 'cms-types'
import { HeroInfoBlock as UIHeroInfoBlock } from 'ui'

import { resolveRelation } from '../helpers'

export default function HeroInfoBlock({ items }: HeroInfoBlockBlock) {
  return (
    <UIHeroInfoBlock
      items={
        items?.map(({ image, title, description, link }) => ({
          title,
          description,
          image: resolveRelation(image),
          url: link,
        })) ?? []
      }
    />
  )
}
