import { PageHeaderBlock } from 'cms-types'
import { LoadableComponentProps, PageHeader as UIPageHeader } from 'ui'

import { richTextToJSX } from '@/helpers/richTextParser'

import { resolveRelation } from '../helpers'

export default function PageHeader({ data, loading }: LoadableComponentProps<PageHeaderBlock>) {
  return loading ? (
    <UIPageHeader loading />
  ) : (
    <UIPageHeader
      data={{
        ...data,
        description: richTextToJSX(data.description),
        image: resolveRelation(data.image),
        priority: !!data.priority,
      }}
    />
  )
}

PageHeader.loadable = true
