import { ShopTeaserBlock } from 'cms-types'
import { LoadableComponentProps, ShopTeaser as UIShopTeaser } from 'ui'

import { resolveRelation } from '../helpers'

export default function ShopTeaser({ data, loading }: LoadableComponentProps<ShopTeaserBlock>) {
  return loading ? (
    <UIShopTeaser loading />
  ) : (
    <UIShopTeaser
      data={{
        ...data,
        image: resolveRelation(data.image),
      }}
    />
  )
}

ShopTeaser.loadable = true
