import { UniqueSellingPointsBlock } from 'cms-types'
import { LoadableComponentProps, UniqueSellingPoints as UIUniqueSellingPoints } from 'ui'

export default function UniqueSellingPoints({
  loading,
  data,
}: LoadableComponentProps<UniqueSellingPointsBlock>) {
  return loading ? (
    <UIUniqueSellingPoints loading />
  ) : (
    <UIUniqueSellingPoints
      data={{
        points: data.points ?? [],
      }}
    />
  )
}

UniqueSellingPoints.loadable = true
