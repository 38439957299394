import { BlogTeaserBlock } from 'cms-types'
import {
  BlogTeaser as BlogTeaserUI,
  BlogTeaserSlide,
  LoadableComponentProps,
  LocalizedComponentProps,
} from 'ui'

import { mapCMSBlogToBlogPostCard } from '@/helpers/mapCMSBlogs'
import { useGlobalTypography } from '@/store/globalTypographyStore'

export default async function BlogTeaser({
  locale,
  data,
  loading,
}: LocalizedComponentProps<LoadableComponentProps<BlogTeaserBlock>>) {
  const mappedSlides = loading
    ? []
    : ((
        await Promise.all(
          data.slides.map(async ({ blogPost, textColor, lightBackgroundOverlay, linkText, id }) => {
            const fetchedBlogPost = await mapCMSBlogToBlogPostCard(
              blogPost,
              useGlobalTypography.getState().blogPostPage.previewLinkTitle,
              locale,
            )

            const { description, image, subtitle } = fetchedBlogPost.preview

            const someFieldExists = [description, image?.id, subtitle].some(Boolean)

            return someFieldExists
              ? {
                  blogPost: fetchedBlogPost,
                  textColor,
                  lightBackgroundOverlay,
                  linkText,
                  id,
                }
              : null
          }),
        )
      ).filter(Boolean) as BlogTeaserSlide[])

  return loading ? (
    <BlogTeaserUI loading />
  ) : (
    <BlogTeaserUI
      data={{
        ...data,
        slides: mappedSlides,
      }}
    />
  )
}

BlogTeaser.loadable = true
