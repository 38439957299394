import { Image } from 'cms-types'
import React from 'react'
import { type LoadableComponentProps, SetGuide as UISetGuide } from 'ui'

type SetGuideProps = {
  title: string
  description: string
  image: Image
}

function SetGuide({ data, loading }: LoadableComponentProps<SetGuideProps>) {
  if (loading) return <UISetGuide loading />
  return <UISetGuide data={data} />
}

export default SetGuide
SetGuide.loadable = true
