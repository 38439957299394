import { CarouselBlock } from 'cms-types'
import { Carousel as UICarousel, LoadableComponentProps } from 'ui'

import { resolveRelation } from '../helpers'

export default function Carousel({ loading, data }: LoadableComponentProps<CarouselBlock>) {
  return loading ? (
    <UICarousel loading />
  ) : (
    <UICarousel
      data={{
        ...data,
        slides:
          data.slides?.map(({ image, ...restSlide }) => ({
            image: resolveRelation(image),
            ...restSlide,
          })) ?? [],
      }}
    />
  )
}

Carousel.loadable = true
