import { SuggestionCardBlock } from 'cms-types'
import { SuggestionCard as UISuggestionCard } from 'ui'

import { pageToUrl, resolveRelation } from '../helpers'

export default function SuggestionCard({
  image,
  title,
  description,
  buttonText,
  page,
}: SuggestionCardBlock) {
  return (
    <UISuggestionCard
      image={resolveRelation(image)}
      title={title}
      description={description}
      buttonText={buttonText}
      url={pageToUrl(page)}
    />
  )
}
