import clsx from 'clsx'
import Link from 'next/link'
import React, { createElement } from 'react'
import { Icon, PayloadImage, sanitizeHtmlId } from 'ui'
import { PayloadVideo } from 'ui/components/PayloadVideo'
import isPayloadImage from 'ui/helpers/isPayloadImage'

import { pageToUrl, renderBlock, resolveRelation } from '@/cms/helpers'

import {
  IS_BOLD,
  IS_CODE,
  IS_ITALIC,
  IS_STRIKETHROUGH,
  IS_SUBSCRIPT,
  IS_SUPERSCRIPT,
  IS_UNDERLINE,
} from './constants'
import { ChildrenNode, ListItemNode, Options } from './types'

const mapHeadersToTableOfContents = (
  headers: { text: string; id: string }[],
  title?: string | null,
) => {
  return (
    <div className='mb-12'>
      <h2 className='h4 mb-4'>{title}</h2>

      <ul>
        {headers.map((header, index) => (
          <li className='mb-5 flex gap-4 text-left' key={index}>
            <Icon
              name='MdArrowForward'
              size={24}
              className='h-6 w-6 shrink-0 grow-0 xl:h-8 xl:w-8'
            />

            <Link href={`#${header.id}`} className='underline-offset-8'>
              <p className='sm lg:!text-[20px]'>{header.text}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const mapListItems = (
  node: ListItemNode,
  listType?: string,
  listParentValue?: string,
): JSX.Element => {
  if (node.children[0].type !== 'list') {
    switch (listType) {
      case 'bullet':
        return (
          <Icon name='MdArrowForward' size={24} className='h-6 w-6 shrink-0 grow-0 xl:h-8 xl:w-8' />
        )
      case 'check':
        return (
          <div className='radius-sm flex h-6 w-6 items-center justify-center border'>
            {node.checked && <Icon name='MdCheck' size={20} />}
          </div>
        )
      case 'number':
        return (
          <p className='ml-2 flex min-w-[20px] justify-center'>{`${listParentValue ?? ''}${
            node.value
          }.`}</p>
        )
      default:
        return <></>
    }
  }
  return <></>
}

const parseLinkHref = (node: ChildrenNode) => {
  if (node.type !== 'link' && node.type !== 'autolink') {
    return ''
  }

  switch (node.fields.linkType) {
    case 'internal':
      return pageToUrl(node.fields.doc) ?? ''

    case 'custom':
      return node.fields.url

    default:
      return ''
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function richTextToJSX(rootNode?: any, options?: Options) {
  const headers: { text: string; id: string }[] = []

  const parse = (
    children?: ChildrenNode[],
    textClassName?: string,
    nestedListLevel?: number,
    listType?: 'bullet' | 'number' | 'check',
    listParentValue?: string,
  ): (JSX.Element | null)[] => {
    return (
      children
        ?.map((node, index) => {
          if (node == null) {
            return null
          }

          switch (node.type) {
            case 'text':
              return createElement(
                node.format & IS_SUBSCRIPT ? 'sub' : node.format & IS_SUPERSCRIPT ? 'sup' : 'p',
                {
                  className: clsx(
                    'inline',
                    node.format & IS_BOLD && 'font-bold',
                    node.format & IS_ITALIC && 'italic',
                    node.format & IS_UNDERLINE && 'underline',
                    node.format & IS_STRIKETHROUGH && 'line-through',
                    node.format & IS_CODE && 'code',
                    options?.textClassName,
                    textClassName,
                  ),
                  key: index,
                  ['data-initial-text']: node.text,
                },
                node.text,
              )

            case 'link':
              if (node.fields == null || node.fields?.doc?.relationTo === 'images') return null

              return (
                <Link
                  className={clsx(
                    'inline underline-offset-4',
                    {
                      left: 'text-left',
                      center: 'text-center',
                      right: 'text-right',
                    }[node.format],
                  )}
                  href={parseLinkHref(node)}
                  target={node.fields.newTab ? '_blank' : '_self'}
                  key={index}
                  rel='noreferrer nofollow'
                  style={{
                    paddingLeft: node.indent * 16,
                  }}
                >
                  {parse(node.children, `underline-animated ${textClassName ?? ''}`.trim())}
                </Link>
              )

            case 'autolink':
              if (node.fields == null || node.fields?.doc?.relationTo === 'images') return null

              return (
                <Link
                  className={clsx(
                    'inline underline-offset-4',
                    {
                      left: 'text-left',
                      center: 'text-center',
                      right: 'text-right',
                    }[node.format],
                  )}
                  href={parseLinkHref(node)}
                  target={node.fields.newTab ? '_blank' : '_self'}
                  key={index}
                  rel='noreferrer nofollow'
                  style={{
                    paddingLeft: node.indent * 16,
                  }}
                >
                  {parse(node.children, `underline-animated ${textClassName ?? ''}`.trim())}
                </Link>
              )

            case 'heading':
              if (node.children[0]?.type === 'text' && (node?.tag === 'h3' || node?.tag === 'h2')) {
                headers.push({
                  text: node.children[0]?.text,
                  id: sanitizeHtmlId(node.children[0]?.text),
                })
              }

              return createElement(
                node.tag,
                {
                  className: clsx(
                    {
                      left: 'text-left',
                      center: 'text-center',
                      right: 'text-right',
                    }[node.format],
                    {
                      h1: 'mb-6 mt-12',
                      h2: 'mb-6 mt-12',
                      h3: 'mb-6 mt-12',
                      h4: 'mb-3 mt-6',
                      h5: 'mb-3 mt-6',
                      h6: 'mb-3 mt-6',
                    }[node.tag],
                  ),
                  style: {
                    paddingLeft: node.indent * 16 || undefined,
                  },
                  key: index,
                  id:
                    (node?.tag === 'h3' || node?.tag === 'h2') && node.children[0]?.type === 'text'
                      ? sanitizeHtmlId(node.children[0]?.text)
                      : undefined,
                },

                parse(
                  node.children,
                  `${
                    {
                      h1: 'h3',
                      h2: 'h4',
                      h3: 'h5',
                      h4: 'h6',
                      h5: 'h6',
                      h6: 'h6',
                    }[node.tag]
                  } ${textClassName ?? ''}`.trim(),
                ),
              )

            case 'quote':
              return (
                <div
                  className='my-12 rounded-md bg-ecruwhite p-5 xl:p-10 [&>*:first-child]:!mt-0 [&>*:last-child]:!mb-0'
                  key={index}
                >
                  {parse(node.children, textClassName ?? '')}
                </div>
              )

            case 'paragraph':
              return (
                <div
                  className={clsx(
                    {
                      left: 'text-left',
                      center: 'text-center',
                      right: 'text-right',
                    }[node.format],
                  )}
                  style={{
                    paddingLeft: node.indent * 16 || undefined,
                    marginBottom: '1.5rem',
                  }}
                  key={index}
                >
                  {parse(node.children, textClassName ?? '')}
                </div>
              )

            case 'list':
              return createElement(
                node.tag,
                {
                  className: clsx(
                    'mb-6 flex flex-col gap-2',
                    {
                      bullet: 'list-disc',
                      number: 'list-decimal',
                      check: 'list-none',
                    }[node.listType],
                    {
                      left: 'text-left',
                      center: 'text-center',
                      right: 'text-right',
                    }[node.format],
                  ),
                  key: index,
                },
                parse(
                  node.children,
                  textClassName ?? '',
                  nestedListLevel == null ? 1 : nestedListLevel + 1,
                  node.listType,
                  listParentValue ?? '',
                ),
              )

            case 'listitem':
              return (
                <li
                  className={clsx(
                    'flex gap-2',
                    {
                      left: 'justify-start text-left',
                      center: 'justify-center text-center',
                      right: 'justify-end text-right',
                    }[node.format],
                    {
                      'items-center gap-2': listType === 'check',
                      'list-none': node.children[0].type === 'list',
                    },
                  )}
                  style={{
                    paddingLeft: node.indent === 0 ? undefined : 16,
                  }}
                  key={index}
                >
                  {mapListItems(node, listType, listParentValue)}
                  <span>
                    {parse(
                      node.children,
                      textClassName ?? '',
                      nestedListLevel,
                      undefined,
                      `${listParentValue ?? ''}${node.value === 1 ? '' : node.value - 1 + '.'}`, //Passing the numeration of the list item to the clild list
                    )}
                  </span>
                </li>
              )

            case 'upload':
              if (isPayloadImage(resolveRelation(node.value))) {
                return (
                  <PayloadImage
                    sizes='(min-width: 903px) 903px, 100vw'
                    key={index}
                    src={resolveRelation(node.value)}
                    className='my-12 w-full overflow-hidden rounded-md xl:my-20'
                  />
                )
              } else {
                return (
                  <PayloadVideo
                    key={index}
                    src={resolveRelation(node.value)}
                    style={{ maxWidth: '100%' }}
                    className='my-12 rounded-md xl:my-20'
                    controls
                    preload='none'
                    controlsList='nodownload'
                  />
                )
              }
            case 'linebreak':
              return <br key={index} />
            case 'block':
              return <div className='my-12 xl:my-20'>{renderBlock(node.fields)}</div>
            default:
              return null
          }
        })
        ?.filter(node => node != null) ?? []
    )
  }

  if (options?.tableOfContents) {
    const richText = parse(rootNode?.root.children)

    return [mapHeadersToTableOfContents(headers, options.tableOfContentsTitle), ...richText]
  }

  return parse(rootNode?.root.children)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTextFromRichText(rootNode?: any) {
  let result = ''

  const parse = (children?: ChildrenNode[]) => {
    children?.forEach(node => {
      if (node == null) {
        return null
      }

      switch (node.type) {
        case 'text':
          result += node.text
          // result += escapeHTML(node.text)
          break

        default:
          parse('children' in node ? node.children : undefined)
      }
    })
  }

  parse(rootNode?.root.children)

  return result
}
