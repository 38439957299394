import { ImageBlock } from 'cms-types'
import { PayloadImage } from 'ui'

import { resolveRelation } from '@/cms/helpers'

export default function Image({ image, imageParams }: ImageBlock) {
  return (
    <div className='radius-md relative overflow-hidden p-5 xl:p-10'>
      <PayloadImage
        src={resolveRelation(image)}
        pictureStyle={{
          width: imageParams?.width ?? undefined,
          height: imageParams?.height ?? undefined,
        }}
        sizes={imageParams?.width ? `${imageParams.width}px` : '100vw'}
        objectFit={imageParams?.height ? 'cover' : 'none'}
      />
    </div>
  )
}
