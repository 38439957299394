import { HeroFeaturesBlock } from 'cms-types'
import { HeroFeatures as UIHeroFeatures } from 'ui'

import { resolveRelation } from '../helpers'

export default function HeroFeatures({
  title,
  topics,
  media,
  gapBetweenTitleAndTopics,
}: HeroFeaturesBlock) {
  return (
    <UIHeroFeatures
      title={title}
      topics={topics}
      mediaDesktop={resolveRelation(media?.imageDesktop ?? media?.videoDesktop)}
      mediaMobile={resolveRelation(media?.imageMobile ?? media?.videoMobile)}
      gapBetweenTitleAndTopics={gapBetweenTitleAndTopics}
    />
  )
}
