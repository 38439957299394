import { CommunityTeaserBlock } from 'cms-types'
import { CommunityTeaser as UICommunityTeaser, LoadableComponentProps } from 'ui'

import { resolveRelation } from '../helpers'

export default function CommunityTeaser({
  data,
  loading,
}: LoadableComponentProps<CommunityTeaserBlock>) {
  return loading ? (
    <UICommunityTeaser loading />
  ) : (
    <UICommunityTeaser
      data={{
        ...data,
        slides: data.slides.map(({ image }) => ({
          image: resolveRelation(image),
        })),
      }}
    />
  )
}

CommunityTeaser.loadable = true
