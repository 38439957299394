import { TeaserSliderBlock } from 'cms-types'
import { useMemo } from 'react'
import { LoadableComponentProps, LoadingText, TeaserSlider as UITeaserSlider } from 'ui'

import { pageToUrl, resolveRelation } from '../helpers'

export default function TeaserSlider(props: LoadableComponentProps<TeaserSliderBlock>) {
  const mappedSlides = useMemo(
    () =>
      props.data?.slides?.map(
        ({
          title,
          description,
          image,
          video,
          mobileImage,
          mobileVideo,
          buttonText,
          isMediaFullWidth,
          page,
          url,
        }) => ({
          title,
          description,
          media: resolveRelation(image || video),
          mobileMedia: resolveRelation(mobileImage || mobileVideo),
          buttonText,
          isMediaFullWidth,
          url: page ? pageToUrl(page) : url ?? '',
        }),
      ) ?? [],
    [props.data?.slides],
  )

  if (props.loading) {
    return (
      <LoadingText className='xxl!h-[790px] mx-5 !h-[561px] pt-4 sm:!h-[488px] md:!h-[536px] lg:!h-[576px] xl:mx-10 xl:mb-10 xl:pt-5' />
    )
  }

  return <UITeaserSlider slides={mappedSlides} />
}

TeaserSlider.loadable = true
