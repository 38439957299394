import { Image, InfoCardListBlock } from 'cms-types'
import { InfoCardList as InfoCardListUI, LoadableComponentProps } from 'ui'

import { richTextToJSX } from '@/helpers/richTextParser'

import { pageToUrl, resolveRelation } from '../helpers'

export default function InfoCardList({ data, loading }: LoadableComponentProps<InfoCardListBlock>) {
  const mappedCards = (data?.cards ?? []).map(({ description, image, tags, ...restCard }) => ({
    description: richTextToJSX(description),
    image: {
      ...image,
      file: resolveRelation(image.file) as Image,
      objectFit: image?.objectFit ?? 'cover',
    },
    tags: (tags ?? []).map(({ text }) => ({ text })),
    ...restCard,
  }))

  return loading ? (
    <InfoCardListUI loading />
  ) : (
    <InfoCardListUI
      data={{
        ...data,
        cards: mappedCards,
        pageLink: data.pageLink
          ? {
              title: data.pageLink.title ?? '',
              url: data.pageLink.page ? pageToUrl(data.pageLink.page) : data.pageLink.url ?? '',
            }
          : undefined,
      }}
    />
  )
}

InfoCardList.loadable = true
