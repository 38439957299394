import { Config, Image, Page } from 'cms-types'
import { createElement } from 'react'
import { sanitizeHtmlId } from 'ui'

import { AnyBlock, blocks } from './blocks'

export const pageToUrl = (
  page?:
    | null
    | string
    | Omit<Page, 'body' | 'type'>
    | {
        value: string | Omit<Page, 'body' | 'type'>
        relationTo: string
      },
): string | undefined => {
  if (page == null) return undefined
  if (typeof page === 'string') return page

  if ('relationTo' in page) {
    switch (page.relationTo) {
      case 'shopPages':
      case 'setCreatorPage':
        return `/shop${pageToUrl(page.value)}`
      case 'blogPosts':
        return `/post${pageToUrl(page.value)}`
      case 'blogListPages':
        return `/hautwissen${pageToUrl(page.value)}`
      case 'productPages':
        return `/produkte${pageToUrl(page.value)}`
      case 'ingredientsPages':
        return `/inhaltsstoffe${pageToUrl(page.value)}`
      case 'files':
        if (page.value && typeof page.value === 'object' && 'url' in page.value)
          return page.value.url as string
        return
      case 'pages':
      default:
        return pageToUrl(page.value)
    }
  }

  return page.slug === 'home' ? '/' : `/${page.slug}`
}

export const resolveRelation = <T extends Config['collections'][keyof Config['collections']]>(
  object?:
    | null
    | string
    | T
    | {
        value: string | T
        relationTo: keyof Config['collections']
      },
): T | undefined => {
  if (typeof object === 'string' || object == null) return undefined

  if ('relationTo' in object) {
    return resolveRelation(object.value)
  }

  return object
}

export const getImage = (image?: string | Image) =>
  image == null ? undefined : typeof image === 'string' ? image : image.url

export const getImageAlt = (image?: string | Image) =>
  image == null || typeof image === 'string' ? undefined : image.alt

export const renderBlock = (block: AnyBlock, locale?: string, key?: string | number) => {
  const BlockComponent = blocks[block.blockType]

  const element = BlockComponent
    ? 'loadable' in BlockComponent && BlockComponent.loadable === true
      ? createElement(BlockComponent, { key, locale, data: block })
      : createElement(BlockComponent, { key, locale, ...block })
    : null

  return element
    ? createElement(
        'div',
        {
          id: block.blockName ? sanitizeHtmlId(block.blockName) : undefined,
          ['data-block-type']: block.blockType,
        },
        element,
      )
    : null
}

export const renderBlocks = (body?: AnyBlock[] | null, locale?: string) => {
  return body?.map((block, i) => renderBlock(block, locale, i)) ?? []
}

export const renderBlockLoading = (block: AnyBlock['blockType'], key?: string | number) => {
  const BlockComponent = blocks[block]

  return BlockComponent && 'loadable' in BlockComponent && BlockComponent.loadable === true
    ? createElement(BlockComponent, { key, loading: true })
    : null
}

export const renderBlocksLoading = (body?: AnyBlock['blockType'][] | null) => {
  return body?.map(renderBlockLoading) ?? []
}
