import { FeatureProductsBlock } from 'cms-types'
import { LoadableComponentProps, LocalizedComponentProps } from 'ui'

import { logger } from '@/helpers/logger'
import { getProductCardsForCMSProductCardBlocks } from '@/helpers/mapCMSProducts'
import { useGlobalTypography } from '@/store/globalTypographyStore'

import { pageToUrl } from '../../helpers'
import FeatureProductsClientWrapper from './ClientWrapper'

export default async function FeatureProducts({
  locale,
  data,
  loading,
}: LocalizedComponentProps<LoadableComponentProps<FeatureProductsBlock>>) {
  if (loading) {
    return <FeatureProductsClientWrapper loading />
  }

  try {
    const mappedProducts = await getProductCardsForCMSProductCardBlocks(data.products, locale)

    return (
      <FeatureProductsClientWrapper
        data={{
          title: data.title,
          subtitle: data.subtitle,
          link:
            data.linkSetting != null && Object.keys(data.linkSetting).length !== 0
              ? { title: data.linkSetting.title ?? '', url: pageToUrl(data.linkSetting.page) ?? '' }
              : undefined,
          products: mappedProducts,
          accessibility: useGlobalTypography.getState().productPage?.accessibility,
        }}
      />
    )
  } catch (err) {
    logger.error('FeatureProducts', err)

    return null
  }
}

FeatureProducts.loadable = true
