import { Config } from 'cms-types'

import { logger } from '@/helpers/logger'

export const CMS_URL = process.env['PAYLOAD_INTERNAL_URL'] ?? process.env.NEXT_PUBLIC_PAYLOAD_URL

export const getCmsSearchParams = (searchParams: Record<string, string | string[]> = {}) =>
  `draft=${searchParams.preview === '1'}${
    searchParams.locale ? `&locale=${searchParams.locale}` : ''
  }${searchParams.depth ? `&depth=${searchParams.depth}` : ''}${
    searchParams.limit ? `&limit=${searchParams.limit}` : ''
  }${searchParams.type ? `&where[type][equals]=${searchParams.type}` : ''}`

export const getCollection = async <T extends keyof Config['collections']>(
  collection: T,
  {
    searchParams = {},
  }: {
    searchParams?: Record<string, string | string[]>
  } = {},
): Promise<Config['collections'][T][]> => {
  // const isPreview = searchParams.preview === '1'
  const url = `${CMS_URL}/api/${collection}?${getCmsSearchParams(searchParams)}`

  const response = await fetch(url)

  if (process.env['NODE_ENV'] === 'production') {
    logger.debug('CMS REQ COLLECTION', url)
  }

  const { docs } = await response.json()

  return docs
}
