import { ContentGridBlock } from 'cms-types'
import { ContentGrid as UIContentGrid, LoadableComponentProps } from 'ui'

import { richTextToJSX } from '@/helpers/richTextParser'

import { resolveRelation } from '../helpers'

export default function ContentGrid({ data, loading }: LoadableComponentProps<ContentGridBlock>) {
  const cardsContent =
    data?.cards?.map(({ title, description, image, video }) => ({
      title,
      description: richTextToJSX(description),
      media: resolveRelation(image || video),
    })) || []
  return loading ? <UIContentGrid loading /> : <UIContentGrid data={cardsContent} />
}

ContentGrid.loadable = true
