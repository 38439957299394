import { PageTitleBlock } from 'cms-types'
import { type LoadableComponentProps, LoadingText } from 'ui'

export default function PageTitle({ data, loading }: LoadableComponentProps<PageTitleBlock>) {
  return (
    <h1 className='h2 p-5 text-center xl:p-10'>
      {loading ? <LoadingText className='inline-block w-[50%]' /> : data.text}
    </h1>
  )
}

PageTitle.loadable = true
