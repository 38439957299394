import { Image, ImageTextBlock } from 'cms-types'
import { ImageText as ImageTextUI } from 'ui'

import { richTextToJSX } from '@/helpers/richTextParser'

export default function ImageText({ image, text, title }: ImageTextBlock) {
  const textContent = richTextToJSX(text)

  return <ImageTextUI image={image as Image} text={textContent} title={title} />
}
