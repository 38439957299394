import { ProductHintCardBlock } from 'cms-types'
import { LocalizedComponentProps, ProductHintCard as ProductHintCardUI } from 'ui'

import { mapProductHintCard } from '@/helpers/mapProductHintCard'

export type ProductHintCardProps = ProductHintCardBlock & {
  useAnExistingTemplate: boolean
}

export default async function ProductHintCard({
  locale,
  ...props
}: LocalizedComponentProps<ProductHintCardProps>) {
  const mappedCardProps = await mapProductHintCard(props, locale)

  return <ProductHintCardUI {...mappedCardProps} />
}
