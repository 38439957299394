import { AccordionBlock } from 'cms-types'
import { AccordionList } from 'ui'

import { richTextToJSX } from '@/helpers/richTextParser'

export default function Accordion({
  panels,
  shouldTriggerNavigation,
  initialOpenIndex,
}: AccordionBlock) {
  return (
    <div className='px-5 py-10 text-center xl:px-10 xl:py-20'>
      <AccordionList
        panels={panels.map(({ title, content }) => ({
          title,
          content: richTextToJSX(content),
        }))}
        shouldTriggerNavigation={shouldTriggerNavigation}
        initialOpenIndex={initialOpenIndex}
      />
    </div>
  )
}
