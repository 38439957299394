import { FeatureBlogPostsBlock } from 'cms-types'
import { FeatureBlogPosts as FeatureBlogPostsUI, LocalizedComponentProps } from 'ui'

import { mapCMSBlogToBlogPostCard } from '@/helpers/mapCMSBlogs'
import { useGlobalTypography } from '@/store/globalTypographyStore'

export default async function FeatureBlogPosts({
  title,
  linkToBlogText,
  linkToBlogURL,
  posts,
  locale,
}: LocalizedComponentProps<FeatureBlogPostsBlock>) {
  const mappedBlogPosts = await Promise.all(
    posts.map(blogPost =>
      mapCMSBlogToBlogPostCard(
        blogPost,
        useGlobalTypography.getState().blogPostPage.previewLinkTitle,
        locale,
      ),
    ),
  )

  return (
    <FeatureBlogPostsUI
      title={title}
      linkToBlogText={linkToBlogText}
      linkToBlogURL={linkToBlogURL}
      posts={mappedBlogPosts}
    />
  )
}
