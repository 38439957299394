import { AxiosError } from 'axios'
import {
  BlogListPage,
  Config,
  IngredientsPage,
  Page,
  ProductPage,
  SearchPage,
  SetCreatorPage,
  ShopPage,
} from 'cms-types'
import { Metadata, ResolvedMetadata, ResolvingMetadata } from 'next'
import { notFound } from 'next/navigation'

import { logger } from '@/helpers/logger'

import { AnyBlock } from './blocks'
import { CMS_URL, getCmsSearchParams } from './getCollection'
import { resolveRelation } from './helpers'

const isAxiosError = (error: unknown): error is AxiosError => {
  return (
    error != null &&
    typeof error === 'object' &&
    ('response' in error || 'request' in error || 'message' in error) &&
    'toJSON' in error
  )
}

const REVALIDATE_TIME = 120

type PageTypes = {
  pages: Page
  shopPages: ShopPage
  ingredientsPages: IngredientsPage
  blogListPages: BlogListPage
  productPages: ProductPage
  searchPage: SearchPage
  setCreatorPage: SetCreatorPage
}

export const getPage = async <C extends keyof PageTypes>(
  collection: C,
  slug: string,
  {
    searchParams = {},
    throwOnNotFound = false,
  }: {
    searchParams?: Record<string, string | string[]>
    throwOnNotFound?: boolean
  } = {},
): Promise<PageTypes[C]> => {
  try {
    const isPreview = searchParams.preview === '1'
    const url = `${CMS_URL}/api/${collection}?${getCmsSearchParams(searchParams)}${
      slug ? `&where[slug][equals]=${slug}` : ''
    }`

    const response = await fetch(url, {
      next: { revalidate: isPreview ? 0 : REVALIDATE_TIME },
    })

    logger.debug('CMS REQ PAGE', url)

    const page = (await response.json()) as { docs: PageTypes[C][]; totalDocs: number }

    if (page.totalDocs === 0 || page.docs.length === 0) {
      if (throwOnNotFound) {
        throw new Error(`Page ${slug} not found`)
      }
    }

    return page.docs[0]
  } catch (error) {
    if (isAxiosError(error)) {
      console.error(`Error fetching page ${slug}:`, error.toJSON())
    } else {
      console.error(`Error fetching page ${slug}:`, error)
    }
    throw error
  }
}

export const getGlobal = async <T extends keyof Config['globals']>(
  global: T,
  {
    searchParams = {},
  }: {
    searchParams?: Record<string, string | string[]>
  } = {},
): Promise<Config['globals'][T]> => {
  const isPreview = searchParams.preview === '1'
  const url = `${CMS_URL}/api/globals/${global}?${getCmsSearchParams(searchParams)}`

  const response = await fetch(url, {
    next: { revalidate: isPreview ? 0 : REVALIDATE_TIME },
  })

  logger.debug('CMS REQ GLOBAL', url)

  return await response.json()
}

export const getCollection = async <T extends keyof Config['collections']>(
  collection: T,
  {
    searchParams = {},
  }: {
    searchParams?: Record<string, string | string[]>
  } = {},
): Promise<Config['collections'][T][]> => {
  const isPreview = searchParams.preview === '1'
  const url = `${CMS_URL}/api/${collection}?${getCmsSearchParams(searchParams)}`

  const response = await fetch(url, {
    next: { revalidate: isPreview ? 0 : REVALIDATE_TIME },
  })

  logger.debug('CMS REQ COLLECTION', url)

  const { docs } = await response.json()

  return docs
}

export const getCollectionItem = async <T extends keyof Config['collections']>(
  id: string,
  collection: T,
  {
    searchParams = {},
  }: {
    searchParams?: Record<string, string | string[]>
  } = {},
): Promise<Config['collections'][T]> => {
  const isPreview = searchParams.preview === '1'
  const url = `${CMS_URL}/api/${collection}/${id}?${getCmsSearchParams(searchParams)}`

  const response = await fetch(url, {
    next: { revalidate: isPreview ? 0 : REVALIDATE_TIME },
  })

  logger.debug('CMS REQ ITEM', url)

  return (await response.json()) as Promise<Config['collections'][T]>
}

export const getCollectionItemByUniqueField = async <C extends keyof Config['collections']>(
  collection: C,
  field: string,
  equals: string,
  {
    searchParams = {},
    throwOnNotFound = false,
  }: {
    searchParams?: Record<string, string | string[]>
    throwOnNotFound?: boolean
  } = {},
): Promise<Config['collections'][C]> => {
  try {
    const url = `${CMS_URL}/api/${collection}?${getCmsSearchParams(
      searchParams,
    )}&where[${field}][equals]=${equals}`

    const response = await fetch(url, {
      next: { revalidate: 0 },
    })

    logger.debug('CMS REQ ITEM_BY_ID', url)

    const item = (await response.json()) as { docs: Config['collections'][C][]; totalDocs: number }

    if (item.totalDocs === 0) {
      if (throwOnNotFound) {
        throw new Error(`Item with ${field}=${equals} not found`)
      } else {
        notFound()
      }
    }

    return item.docs[0]
  } catch (error) {
    if (isAxiosError(error)) {
      console.error(`Error fetching item with ${field}=${equals}:`, error.toJSON())
    } else {
      console.error(`Error fetching item with ${field}=${equals}:`, error)
    }
    throw error
  }
}

export const getPaginatedCollection = async <T extends keyof Config['collections']>(
  collection: T,
  {
    where = '',
    equals = '',
    page = 1, // Add page parameter with a default value of 1
    pageLimit = 10, // Add pageSize parameter with a default value of 10
    sortBy = '',
  }: {
    where?: string
    equals?: string
    page?: number
    pageLimit?: number
    sortBy?: string
  } = {},
  {
    searchParams = {},
  }: {
    searchParams?: Record<string, string | string[]>
  } = {},
): Promise<{ items: Config['collections'][T][]; totalPages: number }> => {
  const isPreview = searchParams.preview === '1'
  const url = `${CMS_URL}/api/${collection}?${getCmsSearchParams(searchParams)}&${
    where && equals && `where[${where}][equals]=${equals}&`
  }page=${page}&limit=${pageLimit}&sort=${sortBy}`

  const response = await fetch(url, {
    next: { revalidate: isPreview ? 0 : REVALIDATE_TIME },
  })

  logger.debug('CMS REQ PAGINATED', url)

  const responseJson = (await response.json()) as {
    docs: Config['collections'][T][]
    totalPages: number
  }

  return {
    items: responseJson.docs,
    totalPages: responseJson.totalPages, // Extract total pages from the response
  }
}

export const getMetadata = async (
  collection: keyof PageTypes,
  slug: string,
  path: string,
  {
    searchParams = {},
  }: {
    searchParams?: Record<string, string | string[]>
  } = {},
  parent?: ResolvingMetadata,
): Promise<Metadata> => {
  try {
    const parentMetadata = (await parent) ?? ({} as ResolvedMetadata)
    const page = await getPage(collection, slug, { searchParams })
    const urlSearchParams = new URLSearchParams()
    const isDevOrStage =
      /^(?:http|https):\/\/dev\./.test(process.env.WEBSITE_PUBLIC_URL) ||
      /^(?:http|https):\/\/stage\./.test(process.env.WEBSITE_PUBLIC_URL)

    for (const [key, value] of Object.entries(searchParams)) {
      if (key === 'locale') continue
      if (Array.isArray(value)) {
        value.forEach(v => urlSearchParams.append(key, v))
      } else {
        urlSearchParams.append(key, value)
      }
    }

    return {
      robots: {
        index: isDevOrStage ? false : !page?.meta?.index ? false : true,
        follow: isDevOrStage ? false : !page?.meta?.follow ? false : true,
      },
      title: page?.meta?.title ?? parentMetadata.title,
      description: page?.meta?.description ?? parentMetadata.description,
      openGraph: {
        images: resolveRelation(page?.meta?.image)?.url ?? parentMetadata.openGraph?.images,
      },
      metadataBase:
        'locale' in searchParams
          ? searchParams.locale === 'en'
            ? new URL(process.env.NEXT_PUBLIC_WEBSITE_PUBLIC_URL_ENGLISH)
            : new URL(process.env.NEXT_PUBLIC_WEBSITE_PUBLIC_URL_GERMAN)
          : new URL(process.env.NEXT_PUBLIC_WEBSITE_PUBLIC_URL_GERMAN),

      alternates: {
        canonical: path
          ? `${path}${urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : ''}`
          : undefined,
      },
    }
  } catch (error) {
    console.error('Failed to resolve metadata', error)

    return {}
  }
}

type SearchInCollectionOptions = {
  /**
   * The key to search within the collection. For nested keys, use `.` notation:
   * ```javascript
   *  'object.key.nestedKey.anotherNestedKey'
   * ```
   *
   * Also support multiple key search within the collection:
   * ```javascript
   *  ['key1', 'key2.nestedKey', 'key3']
   * ```
   */
  key: string | string[]
  query: string
  sort?: string
  page?: number
}

export const searchInCollection = async <T extends keyof Config['collections']>(
  collection: T,
  { key, query, sort = 'id', page = 1 }: SearchInCollectionOptions,
  { searchParams = {} }: { searchParams?: Record<string, string | string[]> } = {},
) => {
  const keysPath = Array.isArray(key)
    ? key.map((key, index) => `[or][${index}][${key}][contains]=${encodeURI(query)}`).join('&')
    : `[${key}]`

  const url = `${CMS_URL}/api/${collection}?${getCmsSearchParams(
    searchParams,
  )}&where${keysPath}[contains]=${encodeURI(query)}&sort=${sort}&page=${page}`

  const response = await fetch(url)

  logger.debug('CMS REQ SEARCH', url)

  return (await response.json()) as {
    docs: Config['collections'][T][]
    totalDocs: number
    hasNextPage: boolean
  }
}

export const getItemCountInCategories = async (
  collection: 'products' | 'blogPosts',
): Promise<Record<string, number>> => {
  const url = `${CMS_URL}/api/${
    collection === 'blogPosts' ? '/blogPostsCountPerCategory' : '/productsCountPerCategory'
  }`

  const response = await fetch(url, { next: { revalidate: REVALIDATE_TIME } })

  return await response.json()
}

export const getBlocksForPage = async (slug: string): Promise<AnyBlock['blockType'][]> => {
  const url = `${CMS_URL}/api/getBlocksForPage/${slug}`

  const response = await fetch(url, {
    next: { revalidate: REVALIDATE_TIME },
  })

  return await response.json()
}

export const getBlocksForSetCreatorPage = async (
  blockPosition: 'previous' | 'after',
): Promise<AnyBlock['blockType'][]> => {
  const url = `${CMS_URL}/api/getBlocksForSetCreatorPage/${blockPosition}`

  const response = await fetch(url, {
    next: { revalidate: REVALIDATE_TIME },
  })

  return await response.json()
}
