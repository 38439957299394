import { SetTeaserBlock } from 'cms-types'
import { SetTeaser as UISetTeaser } from 'ui'

import { pageToUrl, resolveRelation } from '../helpers'

function mapBlockHeight(height?: NonNullable<SetTeaserBlock['height']>['desktop']) {
  return +(height?.useCustomHeight ? height.customHeight! : height?.defaultOption ?? 0)
}

export default function SetTeaser({
  image,
  title,
  description,
  buttonText,
  page,
  height,
}: SetTeaserBlock) {
  const mappedHeight = {
    mobile: mapBlockHeight(height?.mobile),
    desktop: mapBlockHeight(height?.desktop),
  }

  return (
    <UISetTeaser
      image={resolveRelation(image)}
      title={title}
      description={description}
      buttonText={buttonText}
      url={pageToUrl(page)}
      height={mappedHeight}
    />
  )
}
